export const useProModal = () => {
  const { t } = useI18n();
  const description = useState<string>(() => "");
  const showProModal = useState<boolean>(() => false);
  const title = useState<string>(() => t("app_layout.switch_to_pro"));
  return {
    description,
    showProModal,
    title,
  };
};
